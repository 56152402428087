
import { defineComponent, PropType, computed } from 'vue';
import { Category } from '@/shared/interfaces/productDetails';

export default defineComponent({
  name: 'FiltersList',
  props: {
    mainCategory: { type: Object as PropType<Category>, requred: true },
    selectedId: { type: Number },
    subId: { type: Number },
  },
  emits: ['filter'],
  setup(props, { emit }) {
    const isMainSub = computed(() => props.subId === props.mainCategory?.id);

    const path = (id: number) => {
      switch (true) {
        case isMainSub.value:
          return { sub: `${props.mainCategory?.id}`, type: `${id}` };
        case !!props.subId:
          return { sub: `${props.subId}`, type: `${props.mainCategory?.id}`, subType: `${id}` };
        default:
          return { sub: `${id}` };
      }
    };
    const onFilter = (id: number) =>
      isMainSub.value ? emit('filter', id, props.subId) : emit('filter', id);

    return { path, onFilter };
  },
});
