
import { defineComponent, computed, onMounted, reactive } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useVersions } from '@/shared/composables/useVersions';
import { useCategories } from './composables/useCategories';
import { useProductsList } from './composables/useProductsList';
import { routes } from '@/shared/constants/routes';
import { useRouteParams } from '@/shared/composables/useRouteParams';
import { useRouteQuery } from '@/shared/composables/useRouteQuery';
import Categories from '@/shared/components/Categories/Categories.vue';
import FiltersList from './components/FiltersList.vue';
import ProductsInfiniteScroll from '@/shared/components/InfiniteScroll/ProductsInfiniteScroll.vue';
import CategoriesContainer from './components/CategoriesContainer.vue';
import Error from '@/pages/Error.vue';

const pageSize = 12;

export default defineComponent({
  name: 'ProductsList',
  components: {
    Categories,
    FiltersList,
    ProductsInfiniteScroll,
    CategoriesContainer,
    Error,
  },
  setup() {
    const { t } = useTranslations();
    const { categories, error: categoriesError } = useCategories();
    const { products, error, getProducts, isLoading, isLastPage } = useProductsList();
    const { id } = useRouteParams();
    const { sub, type, subType } = useRouteQuery();
    const { isLm } = useVersions();

    const isError = computed(() => categoriesError.value || error.value);
    const isLoadable = computed(() => isLoading.value || isLastPage.value);
    const selectedCategory = computed(
      () => categories.value && categories.value.find((category) => +id === category.id)
    );
    const backBtnText = isLm ? t('product_list_back_btn_lm') : t('product_list_back_btn');

    const state = reactive({
      page: 1,
    });

    const getCategoryId = () => {
      switch (true) {
        case !!subType.value:
          return subType.value;
        case !!type.value:
          return type.value;
        case !!sub.value:
          return sub.value;
        default:
          return id || '';
      }
    };

    const onLoadMore = async () => {
      if (!isLastPage.value) {
        state.page += 1;
        const options = { id: getCategoryId(), page: state.page, pageSize };
        getProducts(options);
      }
    };

    const filterProducts = (category: string) => {
      const options = { id: category, page: 1, pageSize, resetProducts: true };
      getProducts(options);
      state.page = 1;
    };

    onMounted(async () => {
      const options = { id: getCategoryId(), page: 1, pageSize, resetProducts: true };
      getProducts(options);
    });

    return {
      categories,
      routes,
      id,
      selectedCategory,
      t,
      isError,
      products,
      filterProducts,
      isLoadable,
      onLoadMore,
      state,
      isLoading,
      backBtnText,
    };
  },
});
