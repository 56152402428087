import { useRoute } from 'vue-router';
import { watch, ref, Ref } from 'vue';

interface UseRouteQuery {
  sub: Ref<string>;
  type: Ref<string>;
  subType: Ref<string>;
}

export const useRouteQuery = (): UseRouteQuery => {
  const route = useRoute();
  const sub = ref(route.query?.sub as string);
  const type = ref(route.query?.type as string);
  const subType = ref(route.query?.subType as string);

  watch(route, (newRoute) => {
    sub.value = newRoute.query?.sub as string;
    type.value = newRoute.query?.type as string;
    subType.value = newRoute.query?.subType as string;
  });
  return { sub, type, subType };
};
