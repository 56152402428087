import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d3b58e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-h-screen" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "bg-white min-h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_Categories = _resolveComponent("Categories")!
  const _component_CategoriesContainer = _resolveComponent("CategoriesContainer")!
  const _component_FiltersList = _resolveComponent("FiltersList")!
  const _component_ProductsInfiniteScroll = _resolveComponent("ProductsInfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_Error, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseContainer, null, {
            default: _withCtx(() => [
              _createVNode(_component_BackButton, { class: "back-button" }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseHeading, {
                    size: 4,
                    class: "ml-1 lg:ml-2.5 leading-none",
                    mobileSmaller: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.backBtnText), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_CategoriesContainer, null, {
            default: _withCtx(() => [
              (_ctx.categories)
                ? (_openBlock(), _createBlock(_component_Categories, {
                    key: 0,
                    categories: _ctx.categories,
                    class: "mb-6 lg:mb-8"
                  }, null, 8, ["categories"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            id: "filters",
            class: _normalizeClass({ 'filters-container': _ctx.id })
          }, [
            (_ctx.selectedCategory)
              ? (_openBlock(), _createBlock(_component_FiltersList, {
                  key: 0,
                  category: _ctx.selectedCategory,
                  onFilter: _ctx.filterProducts
                }, null, 8, ["category", "onFilter"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.products.length)
            ? (_openBlock(), _createBlock(_component_ProductsInfiniteScroll, {
                key: 1,
                products: _ctx.products,
                isLoadable: _ctx.isLoadable,
                page: _ctx.state.page,
                onLoadMore: _ctx.onLoadMore,
                class: "pt-6"
              }, null, 8, ["products", "isLoadable", "page", "onLoadMore"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}