
import { defineComponent, PropType, reactive } from 'vue';
import { Category } from '@/shared/interfaces/productDetails';
import { routes } from '@/shared/constants/routes';
import { useRouteQuery } from '@/shared/composables/useRouteQuery';
import FilterButton from './FiltersButtons.vue';

export default defineComponent({
  name: 'FiltersList',
  components: { FilterButton },
  props: {
    category: { type: Object as PropType<Category>, requred: true },
  },
  emits: ['filter'],
  setup(props, { emit }) {
    const { sub, type, subType } = useRouteQuery();

    const getSubcategory = (id: number) =>
      props.category?.categories?.find((item: Category) => item.id === id);

    const getType = (id: number, subId: number) => {
      return getSubcategory(Number(subId))?.categories?.find((item: Category) => item.id === id);
    };

    const state = reactive({
      sub: getSubcategory(Number(sub.value)),
      type: getType(Number(type.value), Number(sub.value)),
      subType: Number(subType.value),
    });

    const onSubClick = (id: number) => {
      state.sub = getSubcategory(id);
      state.type = undefined;
      state.subType = 0;
      emit('filter', id);
    };

    const onTypeClick = (id: number, subId: number) => {
      state.type = getType(id, subId);
      state.subType = 0;
      emit('filter', id);
    };

    const onSubTypeClick = (id: number) => {
      state.subType = id;
      emit('filter', id);
    };

    return { routes, onSubClick, onTypeClick, onSubTypeClick, state };
  },
});
