import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86f7643c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-2.5 lg:pt-3 flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mainCategory?.categories?.length)
      ? (_openBlock(), _createBlock(_component_BaseText, {
          key: 0,
          class: "category-title",
          innerHTML: _ctx.mainCategory.title
        }, null, 8, ["innerHTML"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainCategory.categories, (category) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: category.id,
          custom: "",
          to: {
        query: _ctx.path(category.id),
      }
        }, {
          default: _withCtx(({ navigate }) => [
            _createVNode(_component_BaseButton, {
              variant: "secondary",
              size: 0,
              class: _normalizeClass(["category-button", { 'active-category-button': category.id === _ctx.selectedId }]),
              onClick: ($event: any) => {
          _ctx.onFilter(category.id);
          navigate();
        }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(category.title), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick"])
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ], 64))
}