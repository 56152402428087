import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a12fd010"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white pt-3.5 lg:pt-6.5" }
const _hoisted_2 = {
  key: 0,
  class: "pt-5 lg:pt-3"
}
const _hoisted_3 = {
  key: 1,
  class: "pt-5 lg:pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_FilterButton, {
          mainCategory: _ctx.category,
          selectedId: _ctx.state.sub?.id,
          onFilter: _ctx.onSubClick
        }, null, 8, ["mainCategory", "selectedId", "onFilter"]),
        (_ctx.state.sub)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_FilterButton, {
                mainCategory: _ctx.state.sub,
                selectedId: _ctx.state.type?.id,
                subId: _ctx.state.sub?.id,
                onFilter: _ctx.onTypeClick
              }, null, 8, ["mainCategory", "selectedId", "subId", "onFilter"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.state.type)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_FilterButton, {
                mainCategory: _ctx.state.type,
                selectedId: _ctx.state.subType,
                subId: _ctx.state.sub?.id,
                onFilter: _ctx.onSubTypeClick
              }, null, 8, ["mainCategory", "selectedId", "subId", "onFilter"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}