
import { defineComponent } from 'vue';
import { useVersions } from '@/shared/composables/useVersions';

export default defineComponent({
  name: 'CategoriesContainer',
  setup() {
    const { isLm } = useVersions();
    return { isLm };
  },
});
